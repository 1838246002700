@import "_html5reset-1.6.1";
@import "_important";
@import "_m";

//@import "_base/layout";
html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
}
body{
	font-family: Garamond , "Times New Roman" , "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "HGS明朝E" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-transform: rotate(0.001deg);
	-webkit-text-size-adjust: 100%;
  background: #f2eedb url(../img/bg.png) no-repeat bottom right;
}


#pagetop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 99999;
    a {
    	background: url(../imgs/common/totop@2x.png);
    	text-indent: -9999px;
    	width: 45px;
    	height: 45px;
    	display: block;
    	background-size: cover;
    }
} //pagetop

header{
  margin: 0 auto 100px;
  position: relative;
  @media screen and (max-width: 667px) {
    margin-bottom: 60px;
  }
  &:after{
    position: absolute;
    content: "";
    border-bottom: 1px solid #ae2a1f;
    top:69px;
    left: 0;
    display: block;
    width: 100%;
  }
}

section{
  max-width: 960px;
  margin: 0 auto 100px;
  position: relative;
  @media screen and (max-width: 667px) {
    padding:0 3%;
    box-sizing: border-box;
    margin: 0 auto 60px;
  }
  &.sec01{
    max-width: 100%;
    &:after{
      position: absolute;
      content: "";
      border-bottom: 1px solid #ae2a1f;
      top:73px;
      left: 0;
      display: block;
      width: 100%;
      @media screen and (max-width: 667px) {
        content: none;
      }
    }
  }
}

.outline{
  max-width: 100%;
  margin-bottom:80px;
  @media screen and (max-width: 667px) {
    margin-bottom: 50px;
  }
  &__inner{
    max-width: 820px;
    margin: 0 auto 0px;
  }
  &:after{
    position: absolute;
    content: "";
    border-bottom: 1px solid #ae2a1f;
    top:17px;
    left: 0;
    display: block;
    width: 100%;
  }
  table{
    width: 100%;
    font-size: 1.5rem;
    background: #f8f6ef;
    @media screen and (max-width: 667px) {
      font-size: 1.3rem;
    }
    th,td{
      border: 1px solid #bbb;
      padding: 12px;
    }
  }
}

.form{
  iframe{
    width:100%;
    height:1070px;
    background:#f2eedb !important;
    @media screen and (max-width: 667px) {

    }
  }
}

.point{
  display: flex;
  justify-content: space-between;
  max-width: 820px;
  margin: 0 auto 60px;
  li{
    text-align: center;
  }
  @media screen and (max-width: 667px) {
      display: block;
      text-align: center;
    }
}

h2{
  margin-bottom: 30px;
}

footer{
  text-align: center;
  padding: 5px 0;
}

#top{@import "_home";}
