//FONT----------------
@import url(http://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

body {
     font-family: 'Noto Sans Japanese', sans-serif;
     font-size: 100%;
}


.sp {
  @media screen and (min-width: 667px) {
    display: none !important;
  } //min
}

.pc {
  @media screen and (max-width: 667px) {
    display: none !important;
  } //max
}

//中央・右・左寄せ---------

.center {
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.center_sp{
    @media screen and (max-width: 667px) {
      text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
    }
}

.tal{
  text-align: left !important;
}

.tal_sp{
  @media screen and (max-width: 667px) {text-align: left !important;}
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.fl_sp {
  float: left;
  @media screen and (max-width: 667px) {
    float: none;
  } //max
}

.fr_sp {
  float: right;
  @media screen and (max-width: 667px) {
    float: none;
  } //max
}

@mixin clearfix() {
  *zoom:1;
  &:before,
  &:after {
      content:"";
      display:table;
  }
  &:after {
      clear:both;
  }
}

.clear {
  clear: both;
  @include clearfix();
}

.cf {
  @include clearfix();
}

//マージン調整系----------------

//margin-bottom
@for $num from 0 through 10 {
  .mb#{$num} {
    margin-bottom: #{$num*10}px !important;
  }
}

//margin-bottom-sp
@for $num from 0 through 10 {
  .mb#{$num}_sp {
      @media screen and (max-width: 667px) {
    margin-bottom: #{$num*10}px !important;
    }
  }
}

//margin-top
@for $num from 0 through 10 {
  .mt#{$num} {
    margin-top: #{$num*10}px !important;
  }
}

//margin-top-sp
@for $num from 0 through 10 {
  .mt#{$num}_sp {
      @media screen and (max-width: 667px) {
        margin-top: #{$num*10}px !important;
    }
  }
}

//margin-right
@for $num from 0 through 10 {
  .mr#{$num} {
    margin-right: #{$num*10}px !important;
  }
}

//margin-right-sp
@for $num from 0 through 10 {
  .mr#{$num}_sp {
      @media screen and (max-width: 667px) {
    margin-right: #{$num*10}px !important;
    }
  }
}

//margin-left
@for $num from 0 through 10 {
  .ml#{$num} {
    margin-left: #{$num*10}px !important;
  }
}

//margin-left-sp
@for $num from 0 through 10 {
  .ml#{$num}_sp {
      @media screen and (max-width: 667px) {
    margin-left: #{$num*10}px !important;
    }
  }
}

//padding-top
@for $num from 0 through 10 {
  .pt#{$num} {
    padding-top: #{$num*10}px !important;
  }
}

//padding-top-sp
@for $num from 0 through 10 {
  .pt#{$num}_sp {
      @media screen and (max-width: 667px) {
    padding-top: #{$num*10}px !important;
  }
      }
}

//padding-bottom
@for $num from 0 through 10 {
  .pb#{$num} {
    padding-bottom: #{$num*10}px !important;
  }
}

//padding-bottom-sp
@for $num from 0 through 10 {
  .pb#{$num}_sp {
      @media screen and (max-width: 667px) {
    padding-bottom: #{$num*10}px !important;
  }
}
}

//横幅調整-----------------
.w05 {
  width: 4% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}
.w10 {
  width: 9% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w10_sp {
    @media screen and (max-width: 667px) {
  width: 9% !important;
    }
}

.w15 {
  width: 14% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w15_sp {
    @media screen and (max-width: 667px) {
  width: 14% !important;
        }
}

.w20 {
  width: 19% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w20_sp {
    @media screen and (max-width: 667px) {
  width: 19% !important;
    }
}

.w25 {
  width: 24% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w30 {
  width: 29% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w30_sp {
    @media screen and (max-width: 667px) {
  width: 29% !important;
    }
}

.w33 {
  width: 32% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w33_sp {
    @media screen and (max-width: 667px) {
  width: 32% !important;
    }
}

.w35 {
  width: 34% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w35_sp {
    @media screen and (max-width: 667px) {
  width: 34% !important;
        }
}

.w40 {
  width: 39% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w40_sp {
     @media screen and (max-width: 667px) {
  width: 39% !important;
    }
}

.w45 {
  width: 44% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w45_sp {
     @media screen and (max-width: 667px) {
  width: 44% !important;
    }
}

.w49 {
  width: 48% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w50 {
  width: 49% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w50_sp {
     @media screen and (max-width: 667px) {
  width: 49% !important;
    }
}

.w53{
  width: 52% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w55 {
  width: 54% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w60 {
  width: 59% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w65 {
  width: 64% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w60_sp {
     @media screen and (max-width: 667px) {
  width: 59% !important;
    }
}

.w65_sp {
     @media screen and (max-width: 667px) {
  width: 64% !important;
    }
}

.w66 {
  width: 66% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w66_sp {
    @media screen and (max-width: 667px) {
  width: 66% !important;
    }
}

.w70 {
  width: 69% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w70_sp {
    @media screen and (max-width: 667px) {
  width: 69% !important;
    }
}

.w75 {
  width: 74% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w80 {
  width: 79% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w80_sp {
     @media screen and (max-width: 667px) {
  width: 79% !important;
    }
}

.w83 {
  width: 82% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w85 {
  width: 84% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}



.w88 {
  width: 88% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w90 {
  width: 89% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w85_sp {
     @media screen and (max-width: 667px) {
  width: 84% !important;
    }
}

.w90_sp {
     @media screen and (max-width: 667px) {
  width: 89% !important;
    }
}

.w95 {
  width: 94% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w95_sp {
     @media screen and (max-width: 667px) {
  width: 94% !important;
    }
}

.w25_sp {
     @media screen and (max-width: 667px) {
  width: 24% !important;
    }
}


.w75 {
  width: 74% !important;
  @media screen and (max-width: 667px) {
    width: 100%!important;
  }
}

.w75_sp {
     @media screen and (max-width: 667px) {
  width: 74% !important;
    }
}

img.w100 {
  max-width: 100% !important;
}

.flex{
    display: flex;
    flex-wrap: wrap;
    img{
      max-width: 100%;
    }
    @media screen and (max-width: 667px) {
        display: block;
        &.sp-flex{
            display: flex;
            flex-wrap: wrap;
        }
    }
}
.sp-flex{
    @media screen and (max-width: 667px) {
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        flex-wrap: wrap;
    }
}

.between{
    justify-content: space-between;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
}

.a-center{
  align-items: center;
}

.bold{
    font-weight: bold;
}

img{
   @media screen and (max-width: 667px) {
    max-width: 100%;
   }
}
a{
  outline: none;
}
a:focus{
  outline: none;
}
a.fade{
    &:hover{
        opacity: 0.7;
        transition: .3s;
    }
}

/*.expand{
    @media screen and (max-width: 667px) {
        background: url(../imgs/common/expand@2x.png) no-repeat center top;
        padding-top: 57px;
        background-size: 262px 47px;
    }
}*/
