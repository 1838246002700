
.m {
  &-slideIn {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
    transition: all .8s;
    &.inview {
      transition: all .8s;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    &-left {
      transform: translate3d(-50px, 0, 0);
      opacity: 0;
      transition: all .3s;
      &.inview {
        transition: all .8s;
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
    &-right {
      opacity: 0;
      transform: translate3d(50px, 0, 0);
      transition: all .3s;
      &.inview {
        transition: all .8s;
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
  &-slideUp {
    opacity: 0;
    //transform: translate3d(0, 40px, 0);
    //transition: all .4s;
    &.inview {
      //transition: all .8s;
      //opacity: 1;
      //transform: translate3d(0, 0, 0);
      animation: opUp 0.4s ease-in-out forwards;
    }
  }
  &-slideUp02 {
    opacity: 0;
    &.inview {
      animation: opUp 0.4s ease-in-out 0.5s forwards;
    }
  }
  &-slideUp03 {
    opacity: 0;
    &.inview {
      animation: opUp 0.4s ease-in-out 0.8s forwards;
    }
  }
  &-fadeIn {
    opacity: 0;
    transition: all .3s;
    &.inview {
      transition: all .8s;
      opacity: 1;
    }
  }
}

@keyframes opUp {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1
  }
}
